import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { TextField } from "@mui/material";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import NavDropdownLinks from './NavDropdownLinks';
import AppTitleLogo from '../../assets/images/projam-icon.svg';
import "./topbar.scss";
import APP_BASE_URL from "../../components/config/appConfig";
import CreateBacklogIssue from '../Create_Backlogissue';
import { jwtDecode } from "jwt-decode";
import BackgroundLetterAvatarsLogo from '../AvatarLogo';
import NotificationComponent from '../Modal/NotificationModel';

const settings = ["Profile", 'Settings'];
const TopBarNavLinks = styled.a`
text-decoration:none;
font-weight:500;
text-align:center;
`
const TopBarNavDropDownLinks = styled.a`
text-decoration:none;
font-weight:500;
text-align:center;
color:#000000 !important;
`
const LogoText = styled(Box)` 
font-weight: 700;
letter-spacing: 1px; 
color:#010101;
`
function Header(props) {
    const { pages = [], isUserLoggedIn = false, allProjectsList } = props
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [user, setUser] = React.useState('user');
    const { Projects } = useSelector((state) => state.ProjamAppSlice);
    const location = useLocation()
    const navigate = useNavigate()
    const [createModal, setCreateModel] = React.useState(false);
    const onChangeModalVerifyState = () => {
        setCreateModel(true);
    }

    const selectedProjectId = Projects?.selectedProject?.selectedProjectBackLogId

    useEffect(() => {
        getUserName()
    }, [])

    const getUserName = async () => {
        let token = localStorage.getItem('accessToken');
        // const adminEmail = jwtDecode(token);
        // console.log(adminEmail , "Adminemail")
        if (token) {
            const decoded = jwtDecode(token)
            setUser(decoded.name)
        }
    }



    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleSearch = () => {
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const token = localStorage.getItem('accessToken')
    const onLogout = async () => {
        try {
            const response = await fetch(`${APP_BASE_URL}/users/logout`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (response.ok) {
                localStorage.removeItem('accessToken');
                // localStorage.removeItem('backlogId');
                // localStorage.removeItem("projectId");
                // localStorage.removeItem("projectName");
                navigate('/');
            } else {
                const errorMessage = await response.text();
                console.log('Logout failed:', errorMessage);
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
    };



    const handleMenuItemClick = (setting) => {
        if (setting === "Profile") {
            navigate('/projects/profile');
            setAnchorElUser(null);
        } else if (setting === "Settings") {
            navigate('/settings');
        }
    };


    return (
        <AppBar position="fixed" className='px-0 px-lg-3 shadow-sm appbar-main-cls' sx={{ backgroundColor: "#ffffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },

                            textDecoration: 'none',
                            alignItems: 'center'
                        }}
                    >
                        <img src={AppTitleLogo} alt='App-Title-Logo' height={36} className='me-1' />
                        <LogoText fontSize={'20px !important'} >Projam</LogoText>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="#ffffff"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', lg: 'none' },
                            }}
                        >
                            {isUserLoggedIn ? <div>{pages.map((page) => (
                                <MenuItem key={page.text} onClick={handleCloseNavMenu}>
                                    <TopBarNavDropDownLinks href={page.navUrl} className={` ${location.pathname.includes(page.navUrl) ? "active" : ""}`}>{page.text}</TopBarNavDropDownLinks>
                                </MenuItem>
                            ))}
                                <MenuItem onClick={() => {
                                    handleCloseNavMenu()
                                    onChangeModalVerifyState()
                                }}>
                                    <Button
                                        sx={{ my: 2 }}
                                        variant='contained'

                                    >
                                        create
                                    </Button>
                                </MenuItem>
                            </div> : <div>
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <TopBarNavLinks href='#'>{page}</TopBarNavLinks>
                                    </MenuItem>
                                ))}</div>}
                        </Menu>
                    </Box>

                    <Typography
                        noWrap
                        component="a"
                        href="/"

                        sx={{
                            ms: 0,

                            display: { xs: 'flex', md: 'none', },

                            textDecoration: 'none',
                            left: "65px !important",
                            right: "auto !important",
                            position: "fixed"
                        }}
                    >
                        <img src={AppTitleLogo} alt='App-Title-Logo' height={36} className='me-1' />
                        <LogoText fontSize={'20px !important'} >Projam</LogoText>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' }, alignItems: "center", marginLeft: '3%' }}>
                        <Box>
                            {/* This empty Box can be used for any content later */}
                        </Box>

                        {isUserLoggedIn ? (
                            <>
                                {pages.map((page) => {
                                    // Disable only the 'Projects' link if selectedProjectId is not present
                                    const isDisabled = page.text === "Projects" && !selectedProjectId;

                                    return (
                                        <NavDropdownLinks
                                            title={page.text}
                                            key={page.text}
                                            linkUrl={page.navUrl}
                                            allProjectsList={allProjectsList}
                                            disabled={isDisabled}  // Disable 'Projects' if no selectedProjectId
                                            className={`mx-2 ${location.pathname === page.navUrl ? "active" : ""} ${isDisabled ? "disabled-class" : ""}`}
                                        />
                                    );
                                })}
                                <Button
                                    sx={{ m: 2, marginLeft: '30%' }}
                                    variant="contained"
                                    onClick={onChangeModalVerifyState}  // No need for an arrow function here
                                    className="bg-contained-btn-cls"
                                    size="small"
                                >
                                    Create
                                </Button>
                            </>
                        ) : (
                            <>
                                {pages.map((page) => (
                                    <TopBarNavLinks href="#" className="mx-2" key={page.text}>
                                        {page.text}
                                    </TopBarNavLinks>
                                ))}
                            </>
                        )}
                    </Box>

                    <Box sx={{ flexGrow: 0 }} >

                        {isUserLoggedIn ? <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                className="my-2"
                                id="standard-bare"
                                variant="outlined"
                                placeholder="Search ..."
                                type="search"
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <IconButton
                                            className="search-icon-btn"
                                        >
                                            <SearchOutlined
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: "transfarent !important",
                                                    },
                                                }}
                                            />
                                        </IconButton>
                                    ),

                                    className: 'jira-top-search-bar ',
                                }}
                            />
                            <div>
                                <NotificationComponent />
                            </div>
                            <div className='ms-1'>
                                <Tooltip title="Open Profile Settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} className='ms-2'>
                                        {/* <Avatar alt="User">{user}</Avatar> */}
                                        <BackgroundLetterAvatarsLogo LogoTitle={user} />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting}
                                            onClick={() => handleMenuItemClick(setting)}
                                        >
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}

                                    <MenuItem>
                                        <Button variant="contained" className='text-white bg-contained-btn-cls' size="small" sx={{ textTransform: 'none' }} onClick={onLogout}>Logout</Button>
                                    </MenuItem>
                                </Menu>
                            </div>

                        </div> :
                            <>
                                {(!isUserLoggedIn && token) ?
                                    <Box>
                                        <NavLink to='/projects'>
                                            <Button variant="outlined" className=' px-3' sx={{ border: '1px solid', color: '', marginRight: 2 }} size="small" >Dashboard</Button>
                                        </NavLink>
                                        <Button
                                            variant="outlined"
                                            className=' px-3'
                                            sx={{ border: '1px solid #000', color: '#000' }} size="small" onClick={onLogout}>Logout</Button>
                                    </Box>

                                    :
                                    <Box sx={{display:"flex" , gap:1}}>
                                      <NavLink to="/Organisation">
                                        <Button
                                            sx={{ my: 2, display: 'block', border: '1px solid #010101', color: '#010101' }}
                                            variant='outlined'
                                            className=' px-3'
                                        >
                                            Organisation
                                        </Button>
                                    </NavLink>   
                                    
                                    <NavLink to="/login">
                                        <Button
                                            sx={{ my: 2, display: 'block', border: '1px solid #010101', color: '#010101' }}
                                            variant='outlined'
                                            className=' px-3'
                                        >
                                            Login
                                        </Button>
                                    </NavLink>
                                    </Box>
                                    }
                            </>
                        }

                    </Box>
                    {createModal && <CreateBacklogIssue isCreateNewBackIssuse={createModal} setIsCreateNewBackIssue={setCreateModel} />}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Header;


Header.propTypes = {
    pages: PropTypes.array.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired
};



