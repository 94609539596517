import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  MenuItem,
  Box,
  Card,
  Radio,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { values } from 'lodash';
import axios from 'axios';
import API_BASE_URL from '../config/appConfig';

// Step Titles
const steps = ['Contact Details',"Organisation Details", 'select Plan', ];

// Price Options
const priceOptions = [
  { type: 'Basic', price: 100 , duration:1 ,users:200},
  { type: 'Silver', price: 250,duration:3,users:350 },
  { type: 'Gold', price: 500,duration:6,users:500 },
];

// Validation Schemas
const orgSchema = Yup.object().shape({
  organisationName: Yup.string().required('*required'),
  organisationEmail: Yup.string().email('Invalid email').required('*required'),
  numberOfEmps:Yup.number().required('*required'),
  organisationLocation:Yup.string().required('*required'),
  Url:Yup.string().required('*required')
});

const userSchema = Yup.object().shape({
    FirstName:Yup.string().required('*required'),
    LastName:Yup.string().required('*required'),
    email: Yup.string().email('Invalid email').required('*required'),
    mobile: Yup.string()
  .matches(/^[6-9]\d{9}$/, 'Invalid mobile number') 
  .required('*required'),

})

const priceSchema = Yup.object().shape({
  planType: Yup.string().required('Please select a plan'),
});


const RegistrationForm = (snackbarOpen=false ,setError=(()=>{}) , setSnackbarOpen=(()=>{})) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});

  const handleNext = (values) => {
    console.log(values ,'values')
    setFormData({ ...formData, ...values });
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async(data) => {
try{
let registerOrganisation = await axios.post(`${API_BASE_URL}/organisation/register` , data)
console.log(registerOrganisation , 'registerOrganisation')

if(registerOrganisation.status=400){
  let responsess = await registerOrganisation.json()
  const errorMessage = await responsess.message;
  setError(errorMessage);
  setSnackbarOpen(true);
}
console.log(registerOrganisation , 'register from front end')
}
catch(error){

}


    console.log('Form Submitted:', data);
   
  };

  return (
    <Box sx={{ width: '90%', margin: '0 auto', mt: 4 }}>
    <Stepper activeStep={activeStep} sx={{overflow:"scroll" , scrollbarWidth: "none", 
    "&::-webkit-scrollbar": {
      display: "none", 
    }}}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
    <Box sx={{ mt: 2 }}>
      {activeStep === 0 && (
        <Formik
          initialValues={{
            FirstName: "",
            LastName: "",
            email: "",
            mobile: "",
          }}
          validationSchema={userSchema}
          onSubmit={handleNext}
        >
          {({ errors, touched, handleChange, handleBlur, values }) => (
            <Form>
              <TextField
                fullWidth
                label="First Name"
                name="FirstName"
                value={values.FirstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.FirstName && !!errors.FirstName}
                helperText={touched.FirstName && errors.FirstName}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Last Name"
                name="LastName"
                value={values.LastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.LastName && !!errors.LastName}
                helperText={touched.LastName && errors.LastName}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobile"
                value={values.mobile}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.mobile && !!errors.mobile}
                helperText={touched.mobile && errors.mobile}
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button disabled>Back</Button>
                <Button type="submit" variant="contained" color="primary">
                  Next
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
  
      {activeStep === 1 && (
        <Formik
          initialValues={{
            organisationName: "",
            organisationEmail: "",
            organisationLocation: "",
            numberOfEmps:"",
            Url: "",
          }}
          validationSchema={orgSchema}
          onSubmit={handleNext}
        >
          {({ errors, touched, handleChange, handleBlur, values }) => (
            <Form>
              <TextField
                fullWidth
                label="Organization Name"
                name="organisationName"
                value={values.organisationName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.organisationName && !!errors.organisationName}
                helperText={touched.organisationName && errors.organisationName}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Organization Email"
                name="organisationEmail"
                value={values.organisationEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.organisationEmail && !!errors.organisationEmail}
                helperText={touched.organisationEmail && errors.organisationEmail}
                sx={{ mb: 2 }}
              />
               <TextField
                fullWidth
                type='number'
                label="Number of Employees"
                name="numberOfEmps"
                value={values.numberOfEmps}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.numberOfEmps && !!errors.numberOfEmps}
                helperText={touched.numberOfEmps && errors.numberOfEmps}
                sx={{ mb: 2 }}
              />
               <TextField
                fullWidth
                label="Location"
                name="organisationLocation"
                value={values.organisationLocation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.organisationLocation && !!errors.organisationLocation}
                helperText={touched.organisationLocation && errors.organisationLocation}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Website Url"
                name="Url"
                value={values.Url}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Url && !!errors.Url}
                helperText={touched.Url && errors.Url}
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handleBack}>Back</Button>
                <Button type="submit" variant="contained" color="primary">
                  Next
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
  
      {/* Step 3 */}
      {activeStep === 2 && (
        <Formik
          initialValues={{
            planType: "Gold",
            duration: "",
          }}
          validationSchema={priceSchema}
          onSubmit={(values)=>
            handleSubmit({...formData ,planOpted:values.planType })
          }
        >
          {({ errors, touched, handleChange, handleBlur, values, setFieldValue }) => (
            <Form>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Select a Plan:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  mb: 2,
                  overflow: "scroll", 
                        scrollbarWidth: "none", 
                        "&::-webkit-scrollbar": {
                        display: "none", 
                        },
                }}
              >
                {priceOptions.map((option) => (
                  <Card
                    key={option.type}
                    sx={{
                      padding: 2,
                      border:
                        values.planType === option.type
                          ? "2px solid #1976d2"
                          : "1px solid #ccc",
                      cursor: 'pointer',
                    //   display:"flex",
                    //   flex: '1',
                      minWidth:"130px"
                    }}
                    onClick={() => {
                      setFieldValue("planType", option.type);
                      setFieldValue("duration", option.duration);
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h6">{option.type}</Typography>
                      <Radio
                        checked={values.planType === option.type}
                        onChange={() => setFieldValue("planType", option.type)}
                        value={option.type}
                        name="planType"
                        defaultChecked={option.type=='Gold'}
                      />
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Duration: {option.duration}{" "}
                      {option.duration > 1 ? "Months" : "Month"}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Users: {option.users}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Price: ${option.price}
                    </Typography>
                  </Card>
                ))}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handleBack}>Back</Button>
                <Button type="submit" variant="contained" color="primary">
                  Register
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  </Box>
  
  );
};

export default RegistrationForm;
