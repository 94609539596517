import React, { useEffect, useState, useRef } from "react";
import BreadcrumbsComponent from "../../../../components/BreadcrumbsComponent";
import { Box, Typography, Button, ClickAwayListener, Popover, Alert, TextField, Menu, MenuItem } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from '@mui/icons-material/Edit';
import TaskWorkFlowModalPage from "../../../../components/Modal/TaskWorkFlow";
import "../projectpages.scss";
import IconButton from "@mui/material/IconButton";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from '@mui/icons-material/Delete';
import { TbArrowBigRight } from "react-icons/tb";
import { BsBox, BsHourglassSplit } from "react-icons/bs";
import InReviewImg from "../../../../assets/images/in-review-img.svg"
import ProductionReadyImg from "../../../../assets/images/production-ready-img.svg"
import ScrumCycleImage from '../../../../assets/images/clipart-agile-software-development-jira-scrum--removebg-preview.png'
import { NavLink, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import BacklogHeader from '../../../../components/BaclogHeader'
import AddPeopleToProject from '../AddPeopleToProject'
import { useSelector, useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";
import moment from 'moment';
import ActiveSprintsLayout from '../../../../components/ActiveSprintsLayout';
// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Textarea } from '@mui/joy';
import { createIssue, getActiveProjectData, getLastFiveActiveSprintInfo, getSprintsList, SetlastfiveActiveSprintInfo, setSelectedProjectBackLogId, setSkeletonStatus, updateIssue } from '../../../../common/Redux/Reducer';
import { BsCardChecklist } from "react-icons/bs";
import IssueTypeMenuList from '../../../../common/IssueTypeMenuList';

import { updateSprint, updateProject } from '../../../../common/Redux/Reducer';
import { Tooltip } from "@mui/material";
import { issueStatus, taskPriorityList, taskTypesList } from "../../../../utilities/Json";
import { getIssueTypeIcon, getPriorityIcon, getTaskIcon } from "../../../../utilities/Icons";
import CompletConfirmationModal from '../../../../components/Modal/DeleteConfirmationModal';
import ActiveSprintAssigneesLayout from "../../../../components/ActiveSprintAssigneesLayout";
import SearchableDropdown from '../../../../components/Modal/SearchableDropDown';
import BackgroundLetterAvatarsLogo from '../../../../components/AvatarLogo';
import DoneIcon from '../../../../../src/assets/images/DoneIcon.png'
import { IssueStatusCardSkeleton, NormalSkeleton } from "../../../../common/Skeleton";
import SprintStartModal from "../../../../components/ProjectIssues/SprintStartModal";
import DeleteTaskStatus from "../../../../components/DeleteTaskStatus";
import axios from "axios";
import API_BASE_URL from "../../../../components/config/appConfig";
import { useParams } from 'react-router-dom';
import BoltIcon from '@mui/icons-material/Bolt';
// import { object } from "prop-types";

const TasksList = [
  {
    title: "To Do",
    icon: <TbArrowBigRight color="#391085" />,
    bgColor: "#39108526",
    numberOfIssues: 6,
  },
  {
    title: "In Progress",
    icon: <BsHourglassSplit color="#4E77B2" />,
    bgColor: "#4E77B226",
    numberOfIssues: 3,
  },
  {
    title: "In Review",
    icon: (
      <img src={InReviewImg} alt="InReviewImg" width="18px" height="18px" />
    ),
    bgColor: "#FF854726",
    numberOfIssues: 0,
  },
  {
    title: "QA",
    icon: <BsCardChecklist color="#6B54E5" />,
    bgColor: "#6B54E526",
    numberOfIssues: 1,
  },
  {
    title: "Production Ready",
    icon: (
      <img
        src={ProductionReadyImg}
        alt="ProductionReadyImg"
        width="18px"
        height="18px"
      />
    ),
    bgColor: "#52C41A26",
    color: "#52C41A",
    numberOfIssues: 0,
  },
  {
    title: "Done",
    icon: (
      <img src={DoneIcon} alt="Done" width="18px" height="18px" />
    ),
    bgColor: "#52C41A26",
    color: "#52C41A",
    numberOfIssues: 4,
  },
];


const EachTask = styled(Paper)(({ theme }) => ({
  backgroundColor: '#F4F6F9',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'start',
  color: theme.palette.text.secondary,
  // minHeight: '180px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  border: '1px solid #CCCCCC',
  borderRadius: '12px',
  //width:'100%'
  //   minWidth: '195px',
  //  maxWidth:'195px'
  // maxWidth: '280px'
}));

const buttonStyles = {
  backgroundColor: "#60A630",
  color: "#fff",
  borderRadius: "8px",
  textTransform: "none",
  '&:hover': {
    backgroundColor: "#4b8e27",
  }
};

const btnGroupBtnStyles = { backgroundColor: "transparent !important", border: '0px !important', color: "#000000 !important" }


const ActiveSprintPage = ({ sprintData = {} }) => {
  const [searchParams, setSearchparams] = useSearchParams();
  let urlQueryObj = Object.fromEntries([...searchParams]);
  const [modalOpen, setModalOpen] = useState(urlQueryObj?.selectedIssue ? urlQueryObj?.selectedIssue : false);
  const [workFlowModalData, setWorkFlowModalData] = useState({});
  const [openAddPeopleModel, setOpenAddPeopleModel] = useState(false);
  const [groupBy, setGroupBy] = useState("none");
  const [issueType, setIssueType] = useState(taskTypesList[0]);
  const [priority, setPriority] = useState(taskPriorityList[0]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null);
  const [createIssueTitle, setCreateIssueTitle] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedTaskType, setSelectedTaskType] = useState("");
  const [selectPriorityType, setSelectPriorityType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [sprintId, setSprinId] = useState(null);
  const [isSprintEditable, setIsSprintEditable] = useState(false);
  //const [backlogId , setBacklogId] = useState();
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  // const [hoveredIndex, setHoveredIndex] = useState(null);
  const [projectDetails, setProjectDetails] = useState();
  //const [issueStatusList, setIssueStatusList] = useState([]);
  const [ShowCopied, setShowCopied] = useState(false);
  const [openTaskStatusDialog, setOpenTaskStatusDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [remaingWorkingDays, setRemainingWorkDays] = useState(0)
  const inputRef = useRef(null);
  const [selectedIssueName, setSelectedIssueName] = useState(urlQueryObj?.selectedIssue || null);
  const { projectSlug } = useParams();


  const draggedTodoItem = React.useRef(null);
  let draggedSwimlineItem = React.useRef(null);

  const navigate = useNavigate()
  // const location = useLocation()
  const dispatch = useDispatch()

  const filterAssigneesArray = useSelector((state) => state.ProjamAppSlice.assignee)
  const { Projects, Skeleton , assignees } = useSelector((state) => state.ProjamAppSlice)
  const activeSprint = Projects?.selectedProject?.ActiveSprint;
  const contributors = Projects?.selectedProject?.contributors;
  const projectIssuesData = Projects?.selectedProject?.sprintsList;
  let ProjectName = Projects?.selectedProject?.selectedProjectData?.title
  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  let projectId = Projects?.selectedProject?.selectedProjectData?._id
  const ActiveSprintEpicsData = Projects?.selectedProject?.ActiveSprintEpicsData
  const userDetails = Projects?.selectedProject?.loggedUserDetails

  const [issueStatusList, setIssueStatusList] = useState([]);
 
  const [issueCountObj , setIssueCountObj]=useState(activeSprint?.IssuecountObj||{})

  const startDate = moment(new Date());
  const endDate = moment(activeSprint?.endDate);

  const duration = endDate.diff(startDate, "days");


  const defaultIcon = <SyncIcon color="#000" />;
  const defaultBgColor = "#E0E0E0";

  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [selectedEpic, setSelectedEpic] = useState('')
  const [activeSprintEpicsData, setActiveSprintEpicsData] = useState([])


  useEffect(() => {
    if (activeSprint?.IssuecountObj) {
    
      setIssueCountObj(activeSprint?.IssuecountObj)
    }
  }, [activeSprint?.IssuecountObj])

  useEffect(() => {
    if (ActiveSprintEpicsData) {
      const updatedProjectEpicData = ActiveSprintEpicsData?.map((eachData) => ({ ...eachData, icon: <span className="epic-icon-cls"><BoltIcon sx={{ color: '#fff', height: "0.7rem", fontSize: '1.4rem' }} /></span> }))
      setActiveSprintEpicsData(updatedProjectEpicData || [])
    }
  }, [ActiveSprintEpicsData])

  const getHowmanyWorkingDays = async () => {
    try {
      let DatesArray = await Promise.all(Array.from({ length: duration }).map((defaultCurrElem, i) => {
        let nextDate = new Date();
        nextDate.setDate(nextDate.getDate() + i);
        return nextDate

      }))

      let WeekoffDayArray = DatesArray?.filter((eachDate) => {
        return moment(eachDate).format("ddd") == "Sat" || moment(eachDate).format("ddd") == 'Sun'

      })
      // console.log(WeekoffDayArray,'WeekoffDayArray?.length')
      const workindDaysLeft = duration - WeekoffDayArray?.length
      setRemainingWorkDays((prev) => workindDaysLeft)
      //return duration-WeekoffDayArray?.length

    }
    catch (error) {
      console.log(error)
    }
  }

  const handleCopyUrl = async () => {
    try {
      navigator.clipboard.writeText(window.location.href)
      setShowCopied(true)

    }
    catch (error) {
      console.log('failed to copy ')
    }
  }
  const handleEditClick = (index, title) => {
    setIsEditing(true)
    setEditingTaskIndex(index);
    setNewTaskTitle(title);
  };

  const handleSaveIssue = async (index, id) => {

    try {
      //const projectId = localStorage.getItem("projectId")
      const trimedTitle = newTaskTitle.trim();
      const response = await axios.put(`${API_BASE_URL}/projects/UpdateIssueStatus/${id}`, {
        projectId: projectId,
        status: trimedTitle,
      });
      setIssueStatusList(response.data.IssueStatus)
      // setIssueStatusList(updatedIssueStatusList);
    } catch (error) {
      console.error('Error saving issue:', error);
    }
    finally {
      getProjectDetails()
      //  await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
      setNewTaskTitle('');
      setIsEditing(false);
      setEditingTaskIndex(null)
    }
  };
  const handleCancelIssue = () => {
    setIsEditing(false)
    setEditingTaskIndex(null);
    setNewTaskTitle('');
  };


  const getUpdatedBurnDownInfo = async () => {
    try {
      let response = await dispatch(getLastFiveActiveSprintInfo({ backlogId, projectId }));
      dispatch(SetlastfiveActiveSprintInfo(response?.payload))

    }
    catch (error) {
      console.log(error)
    }
  }

  const handleColumnDrop = async (status = '', draggedItemType) => {

    try{
      
    
    // console.log(draggedTodoItem?.current, "draggedTodoItem?.current?")
    //console.log(status, draggedItemType, "status, draggedItemType")

    // let issueStatusArray = [...issueStatusList];
    // let issueStausIndex = issueStatusArray.findIndex(issueStatus => issueStatus.title == status);

    let issueObj = filteredData.find((issue) => {
      return issue._id == draggedTodoItem?.current?.id
    })
    let sts = issueObj?.status
    let dummyIssueCountObj = {...issueCountObj};
   
    if(sts){
   
    dummyIssueCountObj = {...dummyIssueCountObj , [status]:dummyIssueCountObj[status]+1 ,[sts]:dummyIssueCountObj[sts]-1 }
    }
  
    let customArray = [...filteredData]
   
    issueObj = { ...issueObj, status: status , updatedAt: new Date() }
    let indexOfTheIssue = customArray.findIndex(obj => obj._id == draggedTodoItem?.current?.id)

  
    if (!indexOfTheIssue !== -1) {
      customArray?.splice(indexOfTheIssue, 1, issueObj)
     customArray=customArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      setFilteredData(customArray)
      setIssueCountObj(dummyIssueCountObj)
    }

    if (draggedTodoItem?.current) {
      if ((draggedTodoItem?.current?.status?.toLowerCase() !== status?.toLowerCase()) && (draggedItemType === draggedTodoItem?.current?.draggedItemType)) {
        // await dispatch(setSkeletonStatus({ Skeleton: true }))
        await dispatch(updateIssue({ issueId: draggedTodoItem?.current?.id, payload: { status: status } }));
        await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
        // await dispatch(setSkeletonStatus({ Skeleton: false }))
        getUpdatedBurnDownInfo()
        draggedTodoItem.current = null
      } else {
        draggedTodoItem.current = null
      }
    } else {
      draggedTodoItem.current = null;
    }
  }
  catch(error){
    console.log(error)
  }
  };


  const onChangeModalStatus = (data) => {
    setWorkFlowModalData(data);
    setSearchparams({ selectedIssue: data?.name })
    setModalOpen(true);
  };
  const handileNavigation = () => {
    navigate(`/projects/${projectSlug}/backlog`);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'start',
    color: theme.palette.text.secondary,
    minHeight: '75px',
    border: '1px solid #EAEAEA',
    borderRadius: '8px',
    // minWidth:'180px'
    // maxWidth:'195px',


  }));
  const handleCreateIssueClick = (index) => {
    setIsEditing(true);
    setCurrentTaskIndex(index);
  };

  const handleSearch = (text) => {
    setSearchTerm(text);
  };

  const handleStatuUpdate = (value) => {
    setSelectedTaskType(value);
  };

  const handlePriorityUpdate = (priority) => {
    setSelectPriorityType(priority)
  }

  useEffect(() => {
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      const filteredIssues = activeSprint?.issues?.filter((issue) =>
        issue?.summary?.toLowerCase().includes(lowercasedTerm) ||
        issue?.name?.toLowerCase().includes(lowercasedTerm)
      );
      setFilteredData(filteredIssues);
    } else {
      setFilteredData(activeSprint?.issues || []);
    }
  }, [searchTerm, activeSprint]);

  useEffect(() => {
    let filteredData = activeSprint?.issues || [];
    if (selectedEpic) {
      filteredData = filteredData?.filter(
        (eachIssue) => (eachIssue?.name?.toLowerCase() === selectedEpic?.toLowerCase() || eachIssue?.title?.toLowerCase() === selectedEpic?.toLowerCase())
      );
    } else {
      if (selectedTaskType) {
        filteredData = filteredData?.filter(issue =>
          issue?.issueType?.toLowerCase() === selectedTaskType?.toLowerCase()
        );
      }

      if (selectPriorityType) {
        filteredData = filteredData?.filter(issue =>
          issue?.priority?.toLowerCase() === selectPriorityType?.toLowerCase()
        );
      }

      if(assignees.length){
      
        filteredData = filteredData?.filter((Issue)=>{
          // return Issue?.assignee[0]?.employeeName?.includes(assignees)

            return assignees.some((name)=>{
              return Issue?.assignee[0]?.employeeName==name
            })

      })
      }
    
    }
    setFilteredData(filteredData);
  }, [selectedTaskType, selectPriorityType, activeSprint, selectedEpic , assignees]);

  // useEffect(() => {
  //   if (editingTaskIndex !== null) {
  //     inputRef.current?.focus();
  //   }
  // }, [editingTaskIndex , isEditing]);


// useEffect(()=>{
// //update filterData based on the user selected
// // console.log(assignees ,'assignees') 
// try{
//   let IssuesArray = [...filteredData] 

//   let filteredIssuesBasedOnAssignee = IssuesArray?.filter((Issue)=>{
//     return Issue?.assignee[0]?.employeeName?.includes(assignees)
//   })
  
//   setFilteredData(filteredIssuesBasedOnAssignee)
  
//   console.log(filteredData ,'assignee filter')
// }
// catch(error){
// console.log(error , 'error')
// }


// },[assignees])




  useEffect(() => {
    if (Projects?.selectedProject?.selectedProjectData?.IssueStatus) {
      setIssueStatusList(Projects?.selectedProject?.selectedProjectData?.IssueStatus)
    }
  }, [Projects?.selectedProject?.selectedProjectData?.IssueStatus]);

  useEffect(() => {

  }, [issueStatusList, isEditing, showInput, editingTaskIndex])

  const handleTextareaSubmit = async (event, selectedStatus = "To Do", assignee = []) => {
    event.preventDefault();
    const Values = {
      project: projectId,
      issueType: issueType?.name,
      status: selectedStatus,
      summary: createIssueTitle,
      components: "",
      description: "",
      reporter: "",
      assignee: assignee,
      backlog: backlogId,
      sprint: activeSprint?._id,
      priority: priority?.name,
      storyPointEstimate:1
    }
    if (createIssueTitle) {
      // await dispatch(setSkeletonStatus({ Skeleton: true }))
      let valueWithId= {...Values , _id:'123456789'}
      let created = [...filteredData , valueWithId]
      setFilteredData(created)
      setIsEditing(false);
      setCurrentTaskIndex(null);
      setCreateIssueTitle('');
      await dispatch(createIssue(Values))
      await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
     
      // await dispatch(setSkeletonStatus({ Skeleton: false }));

      getUpdatedBurnDownInfo() // updating the burndown info when new issue created
    }
  };
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);


  const handleConfirm = async () => {
    await dispatch(updateSprint({ sprintId: activeSprint?._id, payload: { active: false, completed: true } }));
    await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
    setOpenDeleteDialog(false)
  };

  const handleCompletSprint = () => {
    setOpenDeleteDialog(true)
  }

  const handleClickAway = () => {
    setIsEditing(false);
    setCurrentTaskIndex(null);
  };

  const handleChangeIssueTitle = (event) => {
    setCreateIssueTitle(event.target.value)
  }


  const [anchorEl, setAnchorEl] = useState({ id: "", element: null });

  const handlePopOverClick = (event, rowId) => {
    setAnchorEl({ id: rowId, element: event.currentTarget });
  };

  const handlePopOverClose = () => {
    setAnchorEl({ id: "", element: null });
  };

  const handleAssigneeUpdate = async (taskId, selectedUser) => {
    await dispatch(updateIssue({ issueId: taskId, payload: { assignee: selectedUser ? [selectedUser] : [] } }));
    await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: [] }));
    handlePopOverClose();
  };

  const handleStartSprint = async (values, sprintId) => {
    try {
      await axios.put(`${API_BASE_URL}/sprints/updateSprint/${sprintId}`, {
        active: true,
        completed: false,
      });
      await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setShowInput(false);
    setInputValue("");
    // getProjectDetails();


  }, [projectSlug])

  useEffect(() => {
    getHowmanyWorkingDays()
  }, [duration])



  // const [projectDetails, setProjectDetails] = useState();

  const getProjectDetails = async () => {
    // const projectId = localStorage.getItem('projectId');
    //const token = localStorage.getItem("accessToken");
    try {
      // const response = await axios.get(`${API_BASE_URL}/projects/getProjectsByslug/${projectSlug}` , {
      //   headers:{
      //     Authorization:`Bearer ${token}`
      //   }
      // });

      //
      let projectInfo = await dispatch(getActiveProjectData(projectSlug))
      const { payload } = projectInfo
      if (!payload) {
        navigate('/projects')
      }
      // const issueStatuses = payload?.IssueStatus || [];
      dispatch(setSelectedProjectBackLogId({ id: payload?.backlogs[0] }));
      // setIssueStatusList(issueStatuses);
    } catch (error) {
      console.error('Error fetching project details:', error);
      // throw new Error(error.message);
      navigate('/projects')
    }
  };

  const handleCancelClick = () => {
    console.log("cancelclick")
    setShowInput(false);
    setInputValue(""); // Clear the input field
  };

  const handleSaveClick = async () => {
    if (inputValue) {
      try {

        //const projectId = localStorage.getItem("projectId");
        const response = await axios.post(`${API_BASE_URL}/projects/addIssueStatus`, {
          projectId: projectId,
          status: inputValue.trim(),
        });

        setIssueStatusList(response.data.IssueStatus);
        setShowInput(false);
        setInputValue("");
      } catch (error) {
        console.error("Error adding issue status:", error);
      }
      finally {
        getProjectDetails();
        setNewTaskTitle('');
        // setIsEditing(false);
        // setEditingTaskIndex(null)
        // await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));

      }
    }

  };

  const handleAddTaskItem = () => {
    setShowInput(true);
  }

  const matchingTask = (title) => {
    return (
      TasksList.find((task) => task.title === title) || {
        icon: defaultIcon,
        bgColor: defaultBgColor,
      }
    );
  };

  const handleDeleteTaskStatus = (id) => {
    setSelectedTaskId(id)
    setOpenTaskStatusDialog(true);

  };

  const handleCloseModal = () => {
    setOpenTaskStatusDialog(false);
    setSelectedTaskId(null)
  };

  const handleDeleteConfirm = async () => {
    //console.log(selectedTaskId, "selectedTaskId");
    try {
      // const projectId = localStorage.getItem("projectId");

      await axios.delete(`${API_BASE_URL}/projects/removeIssueStatus/${selectedTaskId}`, {
        data: { projectId: projectId },
      });
    } catch (error) {
      console.log('Error', error);
    } finally {
      getProjectDetails()
      handleCloseModal();
      // await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
    }
  };
  // const testingwidth = 100 / issueStatusList?.length;

  // const [menuAnchor, setMenuAnchor] = useState(null);

  // const handleMenuOpen = (event) => {
  //   setMenuAnchor(event.currentTarget);
  // };

  // const handleMenuClose = () => {
  //   setMenuAnchor(null);
  // };

  // const handleDeleteIssue = async (issueId) => {
  //   console.log("issueId",issueId)
  // try {
  //     const response = await axios.delete(`${API_BASE_URL}/issues/deleteIssue/${issueId}`);
  //     dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
  //     setMenuAnchor(null);
  //   } catch (error) {
  //     console.error('Error deleting issue:', error);
  // }
  // };
  const handleEpicData = (data) => {
    // console.log(data, "setSelectedEpic")
    if (data) {
      setSelectedEpic(data);
    } else {
      setSelectedEpic(data);
    }
  }

  const handleSwimlineDrop = async (item, index, draggedItemType) => {
    const DraggedItem = draggedSwimlineItem?.current
    // console.log(draggedSwimlineItem?.current, "draggedSwimlineItem.current")
    // console.log(item, index, draggedItemType, "item, index, draggedItemType")
    if (DraggedItem && item && (draggedItemType === DraggedItem?.draggedItemType)) {
      // console.log('hello')
      // if ((DraggedItem?.index !== (index - 1)) && (DraggedItem?.index !== index)) {
      if ((DraggedItem?.index !== index)) {
        const reOrdedList = [...issueStatusList]
        if (index > DraggedItem?.index) {
          reOrdedList?.splice(index + 1, 0, DraggedItem?.item)
          reOrdedList?.splice(DraggedItem?.index, 1)
        } else {
          reOrdedList?.splice(index, 0, DraggedItem?.item)
          reOrdedList?.splice(DraggedItem?.index + 1, 1)

        }

        const reOrdedListIds = reOrdedList?.map((eachItem) => eachItem?._id)
        // console.log(reOrdedList, "reOrdedList reOrdedList")
        // console.log(issueStatusList ,'setIssueStatusList');
        // console.log(reOrdedList , 'reOrdedList')
        setIssueStatusList(reOrdedList)

        let projectInfo = await dispatch(updateProject({ id: projectId, payload: { IssueStatus: [...reOrdedListIds] } }))
        const { payload } = projectInfo
        if (payload) {
          // navigate('/projects')
          setIssueStatusList(reOrdedList)
          draggedSwimlineItem.current = null
          getProjectDetails()
        }
      } else {
        draggedSwimlineItem.current = null
      }
    } else {
      draggedSwimlineItem.current = null
    }

  }

  const userAccess = (userDetails?.role === 'Project Manager' || userDetails?.role === 'Project Lead' || userDetails?.role === 'Scrum Master' || userDetails?.role === 'Project Owner') ? true : false
  return (
    <Box className="active-sprint-main-cls">
      <Box className='d-flex flex-column align-items-start flex-md-row align-items-md-center'>
        <div className="ps-2"> {!Skeleton ? <BreadcrumbsComponent /> : <NormalSkeleton width={200} extraStyles="my-1" />}</div>
        {(!Skeleton && activeSprint && Object.keys(activeSprint)?.length > 0 && duration <= 0) &&
          <Alert sx={{ marginLeft: "10%", borderRadius: "8px" }} severity="warning" className="sprint-duration-alert-cls">
            <div className="ps-2 timeline-update-sprint">
              <span fontWeight="500">End of sprint: </span>
              {duration === 0 ? "Sprint ends in 0 days!" : duration < 0 ? "Sprint Duration Completed" : ''}
              <NavLink className='navlink'
                onClick={() => {
                  setOpenModal(true);
                  setSprinId(activeSprint?._id);
                  setIsSprintEditable(true);
                }}
              >
                Update Sprint
              </NavLink>
            </div>
          </Alert>
        }
      </Box>
      <Box className={`my-2 px-3 d-flex  ${duration <= 0 ? 'flex-row align-items-center' : 'flex-column flex-md-row align-items-md-center'}  justify-content-between ${Skeleton ? 'ps-2' : ''}`}>
        {!Skeleton ? <Typography
          sx={{ fontSize: 20, fontWeight: 600, fontFamily: "roboto" }}
          className="mt-md-2"
        >
          {activeSprint?.name ? activeSprint?.name : "Sprint Board"}
        </Typography> : <NormalSkeleton width={200} extraStyles="my-1" />}
        <ButtonGroup
          variant="contained"
          aria-label="outlined button group"
          className="shadow-none justify-content-between justify-content-md-start"
        >
          <Button sx={btnGroupBtnStyles} disabled className="px-0 mx-1 d-none d-md-block">
            <StarBorderOutlinedIcon />
          </Button>
          {Skeleton ? <NormalSkeleton /> : activeSprint && Object.keys(activeSprint)?.length > 0 ? (
            duration >= 0 ?
              <Button sx={btnGroupBtnStyles} className="px-0 mx-1">
                <AccessTimeOutlinedIcon className="me-2" /> {remaingWorkingDays} days
                Remaining
              </Button>
              : null
          ) : null}
          <Tooltip title={ShowCopied ? "url Copied" : "click to copy url"}>
            <Button sx={btnGroupBtnStyles} className="px-0 mx-1 d-none d-md-block" onClick={() => handleCopyUrl()}>
              <ShareOutlinedIcon title='copy' />
            </Button>
          </Tooltip>
          {Skeleton ? <NormalSkeleton variant='rectangular' height={30} /> : activeSprint && Object.keys(activeSprint)?.length > 0 ? (
            <Button
              sx={{
                backgroundColor: "#60A630 !important",
                border: "0px !important",
                color: "#fff !important",
                borderRadius: "8px  !important",
                textTransform: "none !important",
              }}
              onClick={() => {
                handleCompletSprint()
              }}
              className=" mx-1"
            >
              Complete Sprint
            </Button>
          ) : null}
          <Button
            sx={{
              backgroundColor: "#F4F6F9 !important",
              border: "0px !important",
              color: "#000000 !important",
              borderRadius: "8px  !important",
            }}
            className="px-0 mx-1 shadow-sm hover-disable d-none d-md-block"
          >
            <MoreHorizIcon />
          </Button>
        </ButtonGroup>
      </Box>

      <div className="custom-scroll d-flex align-items-center justify-content-between pe-3">
        <BacklogHeader
          setOpenAddPeopleModel={setOpenAddPeopleModel}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          handleStatuUpdate={handleStatuUpdate}
          handlePriorityUpdate={handlePriorityUpdate}
          selectedTaskType={selectedTaskType}
          selectPriorityType={selectPriorityType}
          EpicsData={activeSprintEpicsData}
          // EpicsData={selectedProjectEpicsData}
          handleEpicData={handleEpicData}
          selectedEpic={selectedEpic}
        />{" "}
        <Box className="d-flex flex-row align-items-center d-none d-lg-block">
          {" "}
          {Skeleton ? <NormalSkeleton variant='rectangular' height={30} /> : <ActiveSprintsLayout groupBy={groupBy} setGroupBy={setGroupBy} />}
          {/* {Skeleton ? <NormalSkeleton variant='rectangular' height={30} /> : 
          <Button
            className='hover-disable'
            variant="contained"
            startIcon={<TrendingUpIcon />}
            sx={{
              bgcolor: "#F4F5F7",
              color: "#000000",
              "&:hover": {
                bgcolor: "#F4F5F7",
              },
              textTransform: "none !important",
              marginTop: '8px',
            }}
          >
            Insights11
          </Button>} */}
        </Box>

      </div>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        {/* {(activeSprint && (Object.keys(activeSprint)?.length > 0 || Skeleton)) ? (
          <Box sx={{ flexGrow: 1, flexWrap: "nowrap" }} className="px-3"  >

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {issueStatusList?.map((eachTask, index) => {
                const taskDetails = matchingTask(eachTask.title);
                return (
                  <Box key={eachTask?.title} sx={{ marginRight: 2,}} >
                    {!Skeleton ? (
                      <Item
                        className="shadow-none d-flex align-items-center"
                        sx={{
                          position: 'relative',
                          '&:hover .task-status-icon': { opacity: 1 },
                          width: `100% !important`
                        }}
                      >
                        <IconButton
                          color="inherit"
                          sx={{
                            backgroundColor: `${taskDetails.bgColor} !important`,
                            marginRight: '10px',
                          }}
                        >
                          <Box>{taskDetails.icon}</Box>
                        </IconButton>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {isEditing && editingTaskIndex === index ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                  <TextField
                                    value={newTaskTitle}
                                    onChange={(e) => setNewTaskTitle(e.target.value)}
                                    autoFocus
                                    size="small"
                                    variant="outlined"
                                    sx={{ marginRight: '8px', flexGrow: 1 }}
                                  />
                                  <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row', justifyContent: 'flex-end', ml: '8px'
                                  }}>
                                    <IconButton color="primary" onClick={() => handleSaveIssue(index, eachTask._id)}>
                                      <CheckIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={handleCancelIssue}>
                                      <CloseIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              ) : (
                                <p
                                  className="each-task-status-title-cls mb-1"
                                  onClick={() => handleEditClick(index, eachTask.title)}
                                  style={{ margin: 0, cursor: 'pointer', flexGrow: 1 }}
                                >
                                  {eachTask?.title}
                                </p>
                              )}

                              {!isEditing && editingTaskIndex !== index && (
                                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                  {activeSprint?.IssuecountObj[eachTask?.title] == 0 && (<EditIcon
                                    className="edit-icon task-status-icon"
                                    onClick={() => handleEditClick(index, eachTask?.title)}
                                    sx={{ cursor: 'pointer', opacity: 0, transition: 'opacity 0.3s ease' }}
                                  />)}
                                  {activeSprint?.IssuecountObj[eachTask?.title] == 0 &&
                                    (<DeleteIcon
                                      className="delete-icon task-status-icon"
                                      onClick={() => handleDeleteTaskStatus(eachTask?._id)}
                                      sx={{ marginLeft: '8px', cursor: 'pointer', opacity: 0, transition: 'opacity 0.3s ease' }}
                                    />)}
                                </Box>
                              )}
                            </Box>
                            {!isEditing || editingTaskIndex !== index ? (
                              <p className="each-tasks-cls mb-0">
                                {activeSprint?.IssuecountObj && activeSprint?.IssuecountObj[eachTask?.title]}
                              </p>
                            ) : null}
                          </Box>
                        </Box>
                      </Item>



                    ) : (
                      <IssueStatusCardSkeleton isStatus={true} />
                    )}
                  </Box>

                );
              })}
              {!showInput ? (
                issueStatusList?.length < 8 && (
                  <Box
                    sx={{
                      minWidth: 100,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton color="primary" onClick={handleAddTaskItem}
                      sx={{ backgroundColor: 'rgb(227, 228, 233)' }}>
                      <FiPlus />
                    </IconButton>
                  </Box>
                )
              ) : (
                <Box sx={{ minWidth: 100, marginLeft: '8px', marginTop: '5px', width: "250px" }} >
                  <TextField
                    variant="outlined"
                    size="small"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    fullWidth
                    onFocus={() => inputRef.current?.focus()}
                    sx={{ width: '150px' }}
                  />
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end', 
                    mt:0  
                  }}>
                    <IconButton color="primary" onClick={handleSaveClick}>
                      <CheckIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={handleCancelClick}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <></>
        )} */}

        <div className={`${groupBy === "assignee" ? "py-3" : "pb-3"}`}>
          {(activeSprint && (Object.keys(activeSprint)?.length > 0 || Skeleton)) ?
            (
              groupBy === "assignee" ?
                <ActiveSprintAssigneesLayout
                  issueType={issueType}
                  priority={priority}
                  setPriority={setPriority}
                  searchTerm={searchTerm}
                  setIssueType={setIssueType}
                  activeSprintId={activeSprint?._id}
                  issueStatusList={issueStatusList}
                  currentTaskIndex={currentTaskIndex}
                  isEditing={isEditing}
                  handleCreateIssueClick={handleCreateIssueClick}
                  handleChangeIssueTitle={handleChangeIssueTitle}
                  handleClickAway={handleClickAway}
                  handleTextareaSubmit={handleTextareaSubmit}
                  onChangeModalStatus={onChangeModalStatus}
                  handleColumnDrop={handleColumnDrop}
                  draggedTodoItem={draggedTodoItem}
                  selectedIssueType={selectedTaskType}
                  selectPriorityType={selectPriorityType}
                  newTaskTitle={newTaskTitle}
                  handleSaveIssue={handleSaveIssue}
                  handleCancelIssue={handleCancelIssue}
                  handleEditClick={handleEditClick}
                  handleDeleteTaskStatus={handleDeleteTaskStatus}
                  editingTaskIndex={editingTaskIndex}
                  setNewTaskTitle={setNewTaskTitle}
                  showInput={showInput}
                  handleAddTaskItem={handleAddTaskItem}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  handleCancelClick={handleCancelClick}
                  inputRef={inputRef}
                  handleSaveClick={handleSaveClick}

                />
                : (

                  <Box sx={{ flexGrow: 1 }} className="px-3 mt-3">
                    <Grid container spacing={1} className="sprint-grid-layout-container-cls pb-3" sx={{ flexWrap: 'nowrap', overflowX: 'auto' }} >
                      {issueStatusList?.map((eachTask, index) => {
                        const taskDetails = matchingTask(eachTask.title);
                        return (
                          <Grid item xs={12} sm={6} md={4} lg={2} key={`sprint-grid--${eachTask.title}`} sx={{ display: 'flex', flexDirection: 'column', flexShrink: 0 }}>

                            <Box onDragOver={(e) => e.preventDefault()}
                              onDrop={(e) =>
                                handleSwimlineDrop(
                                  eachTask, index, 'Swimline'
                                )
                              } >
                              {!Skeleton ? (
                                <Item
                                  className={`shadow-none d-flex align-items-center ${userAccess ? 'swimline-cls' : ''}`}
                                  sx={{
                                    position: 'relative',
                                    '&:hover .task-status-icon': { opacity: 1 },
                                  }}
                                  draggable={userAccess}
                                  onDragStart={(e) =>
                                    (draggedSwimlineItem.current = { id: eachTask?._id, item: eachTask, index: index, draggedItemType: 'Swimline' })
                                  }
                                  onDragOver={(e) => e.preventDefault()}
                                >
                                  <IconButton
                                    color="inherit"
                                    sx={{
                                      backgroundColor: `${taskDetails.bgColor} !important`,
                                      marginRight: '10px',
                                    }}
                                  >
                                    <Box>{taskDetails.icon}</Box>
                                  </IconButton>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {userAccess && isEditing && editingTaskIndex === index ? (
                                          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <TextField
                                              value={newTaskTitle}
                                              onChange={(e) => setNewTaskTitle(e.target.value)}
                                              autoFocus
                                              size="small"
                                              variant="outlined"
                                              sx={{ marginRight: '8px', flexGrow: 1 }}
                                            />
                                            <Box sx={{
                                              display: 'flex',
                                              flexDirection: 'row', justifyContent: 'flex-end', ml: '8px'
                                            }}>
                                              <IconButton color="primary" onClick={() => handleSaveIssue(index, eachTask._id)}>
                                                <CheckIcon />
                                              </IconButton>
                                              <IconButton color="secondary" onClick={handleCancelIssue}>
                                                <CloseIcon />
                                              </IconButton>
                                            </Box>
                                          </Box>
                                        ) : (
                                          <p
                                            className="each-task-status-title-cls mb-1"
                                            // onClick={() => handleEditClick(index, eachTask.title)}
                                            style={{ margin: 0, cursor: 'pointer', flexGrow: 1 , maxWidth: "max-content" }}
                                          >
                                            {eachTask?.title}
                                          </p>
                                        )}

                                        {userAccess && !isEditing && editingTaskIndex !== index && (
                                          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                            {issueCountObj[eachTask?.title] == 0 && (<EditIcon
                                              className="edit-icon task-status-icon"
                                              onClick={() => handleEditClick(index, eachTask?.title)}
                                              sx={{ cursor: 'pointer', opacity: 0, transition: 'opacity 0.3s ease' }}
                                            />)}
                                            {issueCountObj[eachTask?.title] == 0 &&
                                              (<DeleteIcon
                                                className="delete-icon task-status-icon"
                                                onClick={() => handleDeleteTaskStatus(eachTask?._id)}
                                                sx={{ marginLeft: '8px', cursor: 'pointer', opacity: 0, transition: 'opacity 0.3s ease' }}
                                              />)}
                                          </Box>
                                        )}
                                      </Box>
                                      {!isEditing || editingTaskIndex !== index ? (
                                        <p className="each-tasks-cls mb-0">
                                          {issueCountObj && issueCountObj[eachTask?.title]}
                                        </p>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </Item>



                              ) : (
                                <IssueStatusCardSkeleton isStatus={true} />
                              )}
                            </Box>


                            <Box sx={{
                              alignSelf: 'stretch', marginTop: '5px',
                              height: '100%'
                            }}>
                              <EachTask
                                className={`shadow-none p-1 task-card-hover-cls`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) =>
                                  handleColumnDrop(
                                    eachTask.title, "issue"
                                  )
                                }
                              >
                                {!Skeleton ? <>
                                  {filteredData.map((eachProjectTask) =>
                                    eachTask?.title?.toLowerCase() ===
                                      eachProjectTask?.status?.toLowerCase() ? (
                                      <Box
                                        key={eachProjectTask._id}
                                        sx={{
                                          backgroundColor: "#FFFFFF",
                                          borderRadius: "5px",
                                          minHeight: "50px",
                                          width: "100%",
                                          border: "1px solid #E3E3E3",
                                          transition: "background-color 0.3s ease",
                                          "&:hover": {
                                            backgroundColor: "#69e6a680",
                                            cursor: "grab",
                                          },
                                        }}
                                        className="pt-1 pb-1 px-2 d-flex flex-column justify-content-between task-item mb-1"
                                        draggable
                                        onDragStart={(e) =>
                                          (draggedTodoItem.current = { id: eachProjectTask?._id, status: eachProjectTask?.status, draggedItemType: 'issue' })
                                        }
                                        onDragOver={(e) => e.preventDefault()}
                                      >
                                        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                                        <div className="d-flex align-items-center" style={{ flexGrow: 1 }}>
                                          <Tooltip title={eachProjectTask.issueType || "Story"}>
                                            {getIssueTypeIcon(eachProjectTask?.issueType)}
                                          </Tooltip>
                                          <Typography sx={{ fontSize: '12px !important', marginLeft: "3px" }}>
                                            {eachProjectTask?.name}
                                          </Typography>
                                        </div>
                                        {/* <IconButton onClick={handleMenuOpen} aria-label="upload picture" component="label" className='p-1'>
                                        <MoreHorizIcon />
                                      </IconButton> */}
                                        {/* <Menu
                                        anchorEl={menuAnchor}
                                        open={Boolean(menuAnchor)}
                                        onClose={handleMenuClose}
                                        PaperProps={{
                                          sx: {
                                            boxShadow: 'none', 
                                            border: '1px solid #E0E0E0', 
                                          },
                                        }}
                                      >
                                        <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
                                        <MenuItem onClick={()=>handleDeleteIssue(eachProjectTask?.summary)}>Delete</MenuItem>
                                      </Menu> */}
                                        {/* </Box> */}
                                        <Typography
                                          className="task-title"
                                          style={{ maxWidth: "max-content" }}
                                          onClick={() =>
                                            onChangeModalStatus(eachProjectTask)
                                          }
                                        >
                                          {eachProjectTask?.summary}
                                        </Typography>
                                        <div
                                          className="d-flex justify-content-between align-items-center mt-2"
                                          style={{ width: "100%" }}
                                        >
                                          <div className="d-flex align-items-center" style={{ margin: "0px", flexGrow: 1 }}>
                                            <Tooltip title={"Story point"}>
                                              <span className="badge rounded-pill">
                                                <Button className="StoryPoint_badge">
                                                  {eachProjectTask?.storyPointEstimate}
                                                </Button>
                                              </span>
                                            </Tooltip>
                                          </div>
                                          <div style={{ flex: 2, justifyContent: 'center' }}>
                                            <Tooltip title={eachProjectTask.priority || "Urgent"}>
                                              {getPriorityIcon(eachProjectTask.priority)}
                                            </Tooltip>
                                          </div>
                                          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                              size="small"
                                              className="assingeeBtnDropdown_main"
                                              aria-describedby={eachProjectTask?._id}
                                              onClick={(event) => handlePopOverClick(event, eachProjectTask?._id)}
                                            >
                                              <BackgroundLetterAvatarsLogo
                                                LogoTitle={eachProjectTask?.assignee ? eachProjectTask?.assignee[0]?.employeeName || eachProjectTask?.assignee[0]?.employeeEmail : ""}
                                                RequiredFontSize="10px  !important"
                                                extraIdName="assignee-layout-avatar-cls"
                                              />
                                            </Button>

                                            <Popover
                                              id={eachProjectTask?._id}
                                              open={anchorEl?.id === eachProjectTask?._id}
                                              anchorEl={anchorEl?.element}
                                              onClose={handlePopOverClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}
                                              PaperProps={{
                                                sx: {
                                                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                                  borderRadius: 0,
                                                  padding: 1,
                                                  width: 270,
                                                },
                                              }}
                                            >
                                              <Box>
                                                <SearchableDropdown
                                                  workFlowModalTaskData={eachProjectTask}
                                                  members={contributors}
                                                  handleAssigneeUpdate={handleAssigneeUpdate}
                                                />
                                              </Box>
                                            </Popover>
                                          </div>
                                          {/* <div className="d-flex align-items-center" style={{ flexGrow: 1, justifyContent: "flex-end" }}>
                                            <Tooltip title={"Story point"}
                                            >
                                              {/* {eachProjectTask.storyPointEstimate} */}
                                          {/* <span className="badge rounded-pill" >
                                                <Button
                                                  className="StoryPoint_badge"
                                                >
                                                  {eachProjectTask?.storyPointEstimate}
                                                </Button>
                                              </span>
                                            </Tooltip>
                                            <Tooltip title={eachProjectTask.priority || "Urgent"}>
                                              {getPriorityIcon(eachProjectTask.priority)}
                                            </Tooltip>

                                            <Button
                                              size="small"
                                              className="assingeeBtnDropdown_main"
                                              aria-describedby={eachProjectTask?._id}
                                              onClick={(event) => handlePopOverClick(event, eachProjectTask?._id)}
                                              style={{ marginLeft: "8px" }}
                                            >
                                              <BackgroundLetterAvatarsLogo
                                                LogoTitle={
                                                  eachProjectTask?.assignee
                                                    ? eachProjectTask?.assignee[0]?.employeeName
                                                    : ""
                                                }
                                                RequiredFontSize="10px  !important"
                                                extraIdName="assignee-layout-avatar-cls"
                                              />
                                            </Button>

                                            <Popover
                                              id={eachProjectTask?._id}
                                              open={anchorEl?.id === eachProjectTask?._id}
                                              anchorEl={anchorEl?.element}
                                              onClose={handlePopOverClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}
                                              PaperProps={{
                                                sx: {
                                                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                                  borderRadius: 0,
                                                  padding: 1,
                                                  width: 270,
                                                },
                                              }}
                                            >
                                              <Box>
                                                <SearchableDropdown
                                                  workFlowModalTaskData={eachProjectTask}
                                                  members={contributors}
                                                  handleAssigneeUpdate={handleAssigneeUpdate}
                                                />
                                              </Box>
                                            </Popover>
                                          </div> */}
                                        </div>

                                      </Box>
                                    ) : null
                                  )}
                                  {index === currentTaskIndex && isEditing ? (
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                      <form
                                        onSubmit={(event) =>
                                          handleTextareaSubmit(event, eachTask.title)
                                        }
                                      >
                                        <Textarea
                                          color="success"
                                          placeholder="What needs to be done?"
                                          className="sprint-grid-layout-create-issue-input"
                                          minRows={2}
                                          maxRows={10}
                                          autoFocus
                                          onChange={handleChangeIssueTitle}
                                          endDecorator={
                                            <div
                                              className="d-flex justify-content-between align-items-end"
                                              style={{ width: "100%" }}
                                            >
                                              <div className="d-flex align-items-end">
                                                <IssueTypeMenuList
                                                  labelText="Issue type"
                                                  ItemsList={taskTypesList}
                                                  setSelectedValue={setIssueType}
                                                  selectedValue={issueType}
                                                  isActiveSprintPage={true}
                                                />
                                              </div>
                                              <div className="d-flex align-items-end">
                                                <IssueTypeMenuList
                                                  labelText="Urgent"
                                                  ItemsList={taskPriorityList}
                                                  setSelectedValue={setPriority}
                                                  selectedValue={priority}
                                                  isActiveSprintPage={true}
                                                />
                                                <Button
                                                  type="submit"
                                                  sx={{ ml: "auto" }}
                                                  size="small"
                                                  variant="contained"
                                                  color="success"
                                                  className="sprint-grid-layout-create-issue-btn"
                                                >
                                                  Create
                                                </Button>
                                              </div>
                                            </div>
                                          }
                                        />
                                      </form>
                                    </ClickAwayListener>
                                  ) : (
                                    <button
                                      onClick={() => handleCreateIssueClick(index)}
                                      // className={`${index !== 0 ? "d-none" : "" } active-sprints-create-issue-btn-cls py-2`}
                                      className={` active-sprints-create-issue-btn-cls py-2`}
                                    >
                                      <FiPlus className="me-1" />
                                      Create Issue
                                    </button>
                                  )}</> : <IssueStatusCardSkeleton isStatus={false} />
                                }
                              </EachTask>
                            </Box>

                          </Grid>)
                      })
                      }
                      {userAccess && <div className="d-flex flex-column  justify-content-start">
                        {!showInput ? (
                          issueStatusList?.length < 8 && (
                            <Box
                              sx={{
                                minWidth: 100,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '10px'
                              }}
                            >
                              <IconButton color="primary" onClick={handleAddTaskItem}
                                sx={{ backgroundColor: 'rgb(227, 228, 233)' }}>
                                <FiPlus />
                              </IconButton>
                            </Box>
                          )
                        ) : (
                          <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: 100, marginLeft: '8px', marginTop: '8px' }} >
                            <TextField
                              variant="outlined"
                              size="small"
                              value={inputValue}
                              onChange={(e) => setInputValue(e.target.value)}
                              fullWidth
                              onFocus={() => inputRef.current?.focus()}
                              sx={{ width: '150px' }}
                            />
                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              mt: 0
                            }}>
                              <IconButton color="primary" onClick={handleSaveClick}>
                                <CheckIcon />
                              </IconButton>
                              <IconButton color="secondary" onClick={handleCancelClick}>
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </div>}

                    </Grid>
                  </Box>
                )
            )



            : (
              <div className="empty-spint-card-cls p-4 mx-3 my-2">
                <div className="text-center py-3">
                  <img
                    src={ScrumCycleImage}
                    alt="ScrumCycleImage"
                    width="200px"
                    height="160px"
                  />
                  <h6 className="empty-spint-card-title">
                    Get Started In the backlog
                  </h6>
                  <p className="empty-spint-card-para">
                    Plan and start a sprint to see issues here.
                  </p>
                  <button
                    className="empty-spint-card-btn px-4 py-2"
                    onClick={handileNavigation}
                  >
                    Go to Backlog
                  </button>
                </div>
              </div>
            )}
        </div>
      </div >
      {/* {modalOpen && <TaskWorkFlowModalPage open={modalOpen} activeSprintName={(activeSprint?.name)} handleChangeModalOpen={() => setModalOpen(!modalOpen)} selectedissueId={workFlowModalData?._id} projectDetails={projectDetails} issueStatusList={issueStatusList} getUpdatedBurnDownInfo={getUpdatedBurnDownInfo} />} */}
      {modalOpen && (<TaskWorkFlowModalPage open={modalOpen} activeSprintName={(activeSprint?.name)} handleChangeModalOpen={() => setModalOpen(!modalOpen)} selectedissueId={workFlowModalData?._id} projectDetails={projectDetails} issueStatusList={issueStatusList} getUpdatedBurnDownInfo={getUpdatedBurnDownInfo} issueName={selectedIssueName} />)}
      {
        openAddPeopleModel && (
          <AddPeopleToProject
            open={openAddPeopleModel}
            ProjectName={ProjectName}
            setOpenAddPeopleModel={setOpenAddPeopleModel}
          />
        )
      }
      <CompletConfirmationModal
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={handleConfirm}
        title="Complet Sprint ?"
        description="Are you sure you want to proceed?"
        btnText="Yes"
        isCompleteConfirm={true}
        buttonStyles={buttonStyles}
        activeSprint={activeSprint}
        AllsprintDetails={projectIssuesData}
        setOpenDeleteDialog={setOpenDeleteDialog}
      />
      {openModal ? (
        <SprintStartModal
          setOpenModal={setOpenModal}
          handleStartSprint={() => handleStartSprint(sprintId)}
          sprintData={activeSprint}
          isSprintEditable={isSprintEditable}
          setIsSprintEditable={setIsSprintEditable}
        />

      ) : null
      }
      {openTaskStatusDialog ? (
        <DeleteTaskStatus
          open={openTaskStatusDialog}
          handleClose={handleCloseModal}
          handleDelete={handleDeleteConfirm} />) : null
      }
    </Box >
  )
}

export default ActiveSprintPage
