import * as React from "react";
import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Tooltip from '@mui/material/Tooltip';

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name, RequiredFontSize) {
  
  return {
    sx: {
      bgcolor: stringToColor(name),
      fontSize: RequiredFontSize ? RequiredFontSize : "inherit",
      
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export default function BackgroundLetterAvatarsLogo({
  LogoTitle = "",
  RequiredFontSize = "12px",
  handleAvatarClick = () => { },
  filterPeoplesArray = [],
  extraIdName = '',
  isCustomised=false,
  profileImage = null
}) {

  return (
    <>
      {LogoTitle?.includes(" ") ? (
        <Tooltip title={LogoTitle} >
          <Avatar
            {...stringAvatar(LogoTitle, RequiredFontSize)}
            className={
              filterPeoplesArray.includes(LogoTitle)
                ? `onAvatar AvtarSelected ${isCustomised && `customAvtarSize`}`
                : `onAvatar ${isCustomised && `customAvtarSize`}`
                
            }
            
            onClick={() => handleAvatarClick()}
            id={extraIdName}
            src={profileImage}
          />
        </Tooltip>
      ) : (
        <Tooltip title={LogoTitle} >
          <Avatar
            className={
              filterPeoplesArray.includes(LogoTitle)
                ? `onAvatar AvtarSelected ${isCustomised && `customAvtarSize`}`
                : `onAvatar ${isCustomised && `customAvtarSize`}`
                
            }
            sx={{
              bgcolor: "#157803",
              fontSize: RequiredFontSize ? RequiredFontSize : "inherit",
            }}
            onClick={() => handleAvatarClick()}
            id={extraIdName}
            src={profileImage}
          >
            {!profileImage &&  LogoTitle[0]?.toUpperCase()}
          </Avatar>
        </Tooltip>
      )}
    </>
  );
}

BackgroundLetterAvatarsLogo.propTypes = {
  LogoTitle: PropTypes.string.isRequired,
};
